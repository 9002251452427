import $ from 'jquery';

const story = {
    els: {
        dots: $('.box--ProgressBar ul li'),
        panels: $('.corpoBlock-historyPanel'),
    },

    init() {
        this.els.dots.on('click', function() {
            story.show($(this));
        });
    },

    show(obj) {
        if (obj.length > 0) {
            obj.addClass('current')
			   .siblings('li')
			   .removeClass('current');

            const panel = $(`div[data-year='${obj.text()}']`);
            panel.addClass('corpoBlock-historyPanel--show')
				 .siblings('.corpoBlock-historyPanel')
				 .removeClass('corpoBlock-historyPanel--show');
        }
    },
};

$(document).ready(function() {
    story.init();
});
