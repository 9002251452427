import $ from 'jquery';

const team = {
    els: {
        btns: $('.corpoBlock-filterGroup .btn'),
        persons: $('.persons'),
        viewAllButton: $('.js-view-all'),
        current: 0,
    },

    init() {
        this.els.btns.on('click', function() {
            const filter = $(this).data('filter');

            if (filter === team.els.current) {
                team.resetFilter();
                team.els.current = 0;
            } else {
                team.els.current = filter;
                team.filterCards(filter);
            }
        });

        this.els.viewAllButton.on('click', function() {
            this.els.persons.find('.person').removeClass('hidden');
            this.els.viewAllButton.hide();
        }.bind(this));

        this.filterByCountry(this.els.persons.data('country'));
    },

    filterByCountry(country) {
        const persons = this.els.persons;

        if (!country) {return;}

        persons.find('.person').each(function() {
            const personCountry = $(this).data('country');
            if (personCountry !== country) {
                persons.append(this);
                $(this).addClass('hidden');
            }
        });
    },

    resetFilter() {
        this.els.persons.children('.person')
            .removeClass('filter-on')
            .removeClass('filter-off');
    },

    applyFilter(objects) {
        objects.siblings('.person')
            .not(objects)
            .removeClass('filter-on')
            .addClass('filter-off');
        objects.removeClass('filter-off')
            .addClass('filter-on');
    },

    filterCards(filter) {
        switch (filter) {
        case 1:
            team.applyFilter(this.els.persons.find(
                `[data-filter='${ filter }']`
            ));
            break;
        case 2:
            team.applyFilter(this.els.persons.find(
                `[data-filter='${ filter }']`
            ));
            break;
        case 3:
            team.applyFilter(this.els.persons.find(`[data-filter='${ filter }']`));
            break;
        default:
            break;
        }
    },
};

$(document).ready(function() {
    team.init();
});
