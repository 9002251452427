const URI = require("URIjs");
const _ = require("underscore");

const queryKeysToCampaignKeysMap = {
    utm_campaign: "campaignName",
    utm_medium: "campaignMedium",
    utm_source: "campaignSource",
    utm_content: "campaignContent",
    utm_term: "campaignKeyword",
};

function extractCampaignParameters(query) {
    const campaignParameters = {};
    const restParameters = {};
    _.each(query, (value, key) => {
        const settingKey = queryKeysToCampaignKeysMap[key];
        if (settingKey) {
            campaignParameters[settingKey] = value;
        } else {
            restParameters[key] = value;
        }
    });
    return {
        campaignParameters,
        restParameters,
    };
}

function setItem(key, value) {
    try {
        localStorage.setItem(key, value); // eslint-disable-line no-restricted-globals
    } catch (e) {
        // Could not store. The user is probably in private mode
    }
}

const uri = new URI();
const parseAsMap = true;
const query = uri.search(parseAsMap);
const { campaignParameters } = extractCampaignParameters(query);

function persistCampaign(campaign) {
    return setItem("marketingCampaign", campaign);
}

if (!_.isEmpty(campaignParameters)) {
    persistCampaign(JSON.stringify(campaignParameters));
}

var Tracker = require("@lrqdo/tracker");

var TrackerProxy = function() {
    var appId = window.env === "production" ? "334545491" : "386263849";
    this.tracker = new Tracker(appId);

    //page view
    if (typeof window.ga === "function") {
        this.tracker.sendPageView();
    }
};

export default new TrackerProxy();
