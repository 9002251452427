/* globals TimelineMax, TweenMax */

import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

// are we on the open page ?
if ($('.openHeader').length > 0) {
    $('#host-modal').on('show.bs.modal', function(_e) {
        const button = $(_e.relatedTarget);
        const modal = $(this);
        modal.find('.host-name').html(button.data('name'));
        modal.find('.host-photo').attr('src', button.data('photo'));
        modal.find('.host-text').html(button.data('text'));
        modal.find('.host-quote').html(button.data('quote'));
        modal.find('.host-age').text(button.data('age'));
        modal.find('.host-job').html(button.data('job'));
        modal.find('.host-interest').html(button.data('interest'));
        modal.find('.assembly-info').html(button.data('assembly-info'));
    });

    const controller = new ScrollMagic.Controller();

    // BUY ONLINE
    const tweenHeader = new TimelineMax()
	    .add([
	        TweenMax.fromTo('.openHeader .layer-0', 1000, { css: { bottom: '20%' } }, { css: { bottom: '-20%' } }),
	        TweenMax.fromTo('.openHeader .layer-1', 1000, { css: { bottom: '13%' } }, { css: { bottom: '-10%' } }),
	        TweenMax.fromTo('.openHeader .layer-2', 1000, { css: { bottom: '2%' } }, { css: { bottom: '-15%' } }),
	        TweenMax.fromTo('.openHeader .layer-4', 1000, { css: { bottom: '8%' } }, { css: { bottom: '-7%' } }),
	        TweenMax.fromTo('.openHeader .layer-5', 1000, { css: { bottom: '0' } }, { css: { bottom: '0' } }),
    	]);

    new ScrollMagic.Scene({ triggerElement: '.openHeader', duration: $(window).height(), offset: $(window).height() / 2 })
	    .setTween(tweenHeader)
	    .addTo(controller);
}
