/*
/!\
- Please keep in sync with the same file in lrqdo/front-web
- This needs to be called before pageload and not in a setTimeout:
  http://stackoverflow.com/questions/31289530/load-ios-smart-app-banner-through-javascript
*/

var uri = require('URIjs');

var LOCALES_IN_APP_STORE = ['fr', 'en'];
var COUNTRIES_IN_APP_STORE = ['fr', 'be', 'uk'];

module.exports = function(userAgent, url) {
    var isIPad = /iPad/.test(userAgent);

    var path = uri(url).path();
    var isLocaleInAppStore = LOCALES_IN_APP_STORE.some(function(locale) {
      return path.indexOf(locale) === 1
    });

    var removeSecondLevelDomain = true; // .co is a SLD in .co.uk
    var country = uri(url).tld(removeSecondLevelDomain);
    var isCountryInAppStore = COUNTRIES_IN_APP_STORE.indexOf(country) !== -1
        || country === ''; // In development, localhost OR IPs have no tld

    if (isIPad || !isLocaleInAppStore || !isCountryInAppStore) {
        var meta = document.querySelector('meta[name=apple-itunes-app]');
        if (meta) {
          meta.parentNode.removeChild(meta);
        }
    }
}
