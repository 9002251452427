import "../app/assets/styles/main.scss";
import "core-js/es6/promise";

import $ from "jquery";
import _ from "underscore";
import URI from "URIjs";
import "../app/assets/scripts/open";

// for jquery-unveil
// and bootstrap shit
window.jQuery = $;

// This is import, but with deferred parsing
import(
    /* webpackMode: "eager" */
    "bootstrap-sass/vendor/assets/javascripts/bootstrap/modal"
);
import(
    /* webpackMode: "eager" */
    "bootstrap-sass/vendor/assets/javascripts/bootstrap/dropdown"
);
import(
    /* webpackMode: "eager" */
    "bootstrap-sass/vendor/assets/javascripts/bootstrap/tab"
);
import(
    /* webpackMode: "eager" */
    "../app/assets/scripts/history"
);
import(
    /* webpackMode: "eager" */
    "../app/assets/scripts/scrollmenu"
);
import(
    /* webpackMode: "eager" */
    "../app/assets/scripts/team"
);

const mapBoxGeocoding = require("@mapbox/mapbox-sdk/services/geocoding");

/*
/!\
- This needs to be called before pageload and not in a setTimeout:
  http://stackoverflow.com/questions/31289530/load-ios-smart-app-banner-through-javascript
*/
const removeAppStoreBanner = require("./removeAppStoreBanner");
removeAppStoreBanner(navigator.userAgent, window.location.href);

const mapboxApiKey =
    "pk.eyJ1IjoibHJxZG8iLCJhIjoibzBPTzE0ZyJ9.-udp6yXoZGNOC7u3wF4GkQ";

const geocodingService = mapBoxGeocoding({ accessToken: mapboxApiKey });

require("typeahead.js");
const trackerProxy = require("./trackerProxy"); // send page view

(function() {
    function getCoordinates(hit) {
        return hit && hit.geometry && hit.geometry.coordinates
            ? {
                  lat: hit.geometry.coordinates[1],
                  lng: hit.geometry.coordinates[0],
              }
            : {};
    }

    function extractContextInfo(hit) {
        const infos = {};
        _.each(hit.context, (context) => {
            if (context.id.match(/postcode/)) {
                infos.postcodes = context.text;
            }
            if (context.id.match(/country/)) {
                infos.countryname = context.text;
                infos.countrycode = context.short_code;
            }
        });
        return {
            ...infos,
            ...getCoordinates(hit),
        };
    }

    function lrqdoAddressAutocomplete() {
        const language = _.contains(
            ["it", "ca", "es", "de", "en", "nl", "da"],
            window.currentLanguage
        )
            ? window.currentLanguage
            : "fr";
        const mapboxGeocodingAdapter = (input, callback) => {
            geocodingService
                .forwardGeocode({
                    query: input,
                    limit: 5,
                    language: [language],
                    countries: [
                        "FR",
                        "IT",
                        "DE",
                        "CH",
                        "NL",
                        "BE",
                        "ES",
                        "MQ",
                        "GP",
                        "RE",
                    ],
                })
                .send()
                .then((response) => {
                    const { body } = response;
                    return callback(
                        body.features.map((hit) => {
                            return {
                                ...hit,
                                value: `${hit.place_name}`,
                            };
                        })
                    );
                });
        };

        function selectPlace(data) {
            const { lat, lng, ...query } = extractContextInfo(data);
            const queryBuild = URI.buildQuery(query);

            window.location = `${$(".js-city-submit").data(
                "url"
            )}assemblies?${queryBuild}#11/${lat}/${lng}`;
        }

        // init address typeahead
        const cityInput = $("#city");
        cityInput.typeahead(
            { autoselect: true },
            {
                name: "city",
                minLength: 2,
                source: _.debounce(mapboxGeocodingAdapter, 300),
            }
        );

        cityInput.on(
            "typeahead:selected",
            _.bind(function(e, data) {
                selectPlace(data);
            }, this)
        );

        $(".js-city-submit").on("click", function() {
            const val = cityInput.typeahead("val");
            if (!val) {
                return;
            }

            // manually geocode, use first autocomplete result
            mapboxGeocodingAdapter(val, function(data) {
                if (data.length) {
                    cityInput.typeahead("val", data[0].value);
                    selectPlace(data[0]);
                }
            });
        });
    }

    /**
     * implements autocomplete on the address field for the new homepage
     */
    if ($(".homepage").length) {
        lrqdoAddressAutocomplete();
    }
})();

$(window).click(function() {
    $("#langChooserDropdown").hide();
});
$("#langChooser").click(function(event) {
    event.stopPropagation();
    $("#langChooserDropdown").show();
});

$(document).ready(function() {
    $('.burger-menu').click(function() {
        $('.mobile-menu, .homepage').toggleClass('mobile-menu--open');
    });
});
