import $ from 'jquery';

const scrollToMenu = {
    els: {
        scroll_links: $('.scroll-to-el'),
    },

    init() {
        this.els.scroll_links.on('click', function(e) {
            const hash = e.currentTarget.hash;
            if (hash === '#buy' && $(hash).length > 0) {
                e.preventDefault();
                scrollToMenu.scrollOn(hash);
                document.location.hash = hash;
            }
        });
    },

    scrollOn(hash) {
        $('html, body').animate({
            scrollTop: $(hash).offset().top,
        }, 600);
    },
};

$(document).ready(function() {
    scrollToMenu.init();
});
